'use client'

import { useConsent } from '@/contexts/ConsentContext'
import getAPI from '@/lib/utils/api-client'
import { cn } from '@/lib/utils/utils'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import validator from 'validator'
import { getLangCode, I18nDictionary, useI18n } from '../i18n/lang-context'
import Reveal from '../reveal'
import { Button } from '../ui/button'
import { FlexCol } from '../ui/flex'
import { Input } from '../ui/input'
import { useCookiesConsentDialog } from './cookies-dialog'
import { Section } from './section'
import { SectionTitle } from './section-title'

const I18N: I18nDictionary = {
  title: {
    en: 'Our monitoring of crypto investment for the real economy, in your email inbox.',
    fr: 'Notre veille sur l’utilisation des crypto-actifs dans l’économie réelle, directement dans votre boîte e-mail.'
  },
  desc: {
    en: 'Subscribe to our newsletter to receive our latest articles and news!',
    fr: 'Inscrivez-vous à notre newsletter pour recevoir nos derniers articles et actualités !'
  },
  emailPlaceholder: { en: 'Email address', fr: 'Adresse email' },
  subscribe: { en: 'Subscribe', fr: 'S’inscrire' },
  emailInvalid: {
    en: 'Invalid email address !',
    fr: 'Adresse e-mail invalide !'
  },
  alreadyExists: {
    en: 'Oops! It looks like this email address is already subscribed...',
    fr: 'Oups ! Il semble que cette adresse e-mail soit déjà inscrite...'
  },
  unknownError: {
    en: 'Oops ! An unknown error occured...',
    fr: 'Oups ! Une erreur inconnue s\'est produite...'
  },
  keepInTouch: {
    en: 'Thank you! You\'ve successfully subscribed. We\'ll be in touch soon!',
    fr: 'Merci ! Votre e-mail a été enregistré avec succès. À bientôt !'
  },
  subscribing: {
    en: 'Subscribing...',
    fr: 'Inscription...'
  },
  subscribeNew: {
    en: 'Subscribe another email',
    fr: 'Abonnez une autre adresse e-mail'
  }
}

export function NewsletterSubscription({ background2 }: { background2?: boolean }) {

  const i18n = useI18n(I18N)
  const api = getAPI()
  const [email, setEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [subscribing, setSubscribing] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const checkConsent = useConsentCheck()

  const validateEmail = (e: any, showInvalidMsg: boolean = false) => {
    const email = e.target.value.toLowerCase()
    if (email !== '') {
      if (validator.isEmail(email)) {
        setErrorMsg('')
        setEmailValid(true)
      } else {
        if (showInvalidMsg) setErrorMsg(i18n.emailInvalid)
        setEmailValid(false)
      }
    } else {
      setErrorMsg('')
      setEmailValid(false)
    }
    setEmail(email)
  }

  function useConsentCheck() {
    const { openCookiesConsentDialog } = useCookiesConsentDialog()
    const { consent } = useConsent()

    return () => {
      if (!consent.contact) {
        openCookiesConsentDialog('simple')
        return false
      }
      return true
    }
  }

  const subscribe = async () => {
    if (!checkConsent()) return
    if (!emailValid) return
    try {
      setSubscribing(true)
      const subscription = await api.post('/subscriptions', { email, langCode: getLangCode() })
      console.log('POST /subscriptions RESPONSE', subscription)
      setSubscribed(true)
      setEmailValid(false)
      toast.success(i18n.keepInTouch, { autoClose: 5000 })
    } catch (e: any) {
      console.error('POST /subscriptions ERROR: ' + e.errorCode, e)
      if (e?.errorCode === 409) {
        setErrorMsg(i18n.alreadyExists)
        setEmailValid(false)
        toast.warn(i18n.alreadyExists, { autoClose: 5000 })
      } else {
        setErrorMsg(i18n.unknownError)
        toast.error(i18n.unknownError, { autoClose: 5000 })
      }
    }
    setSubscribing(false)
  }

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  if (!mounted) return null

  return (
    <Section containerClassName={cn(background2 ? 'bg-background2' : 'bg-background')}>

      <FlexCol id='NEWSLETER_PANEL' className={'relative w-full max-w-5xl gap-8 overflow-hidden rounded-lg bg-primary p-4 text-white sm:p-16'}>

        <div id='TRIANGLE_1' className='absolute -left-40 top-[80%] z-[1] h-full w-[200%] rotate-12 bg-white opacity-20 md:-left-80 xl:top-[90%]' />
        <div id='TRIANGLE_2' className='absolute -right-40 top-[80%] z-[2] h-full w-[200%] -rotate-12 bg-black opacity-10 md:-right-80 xl:top-[90%]' />

        <SectionTitle title={i18n.title} desc={i18n.desc} contrast={true} titleMaxWidth='max-w-4xl' descMaxWidth='max-w-xl' className='relative z-10' />

        {!subscribed && <Reveal id='SUBSCRIBE' duration={250} delay={250} scale={0} className='relative z-10 flex w-full flex-col flex-nowrap items-center justify-center gap-8 md:flex-row md:gap-4'>
          <div className='relative w-full max-w-sm'>
            <Input placeholder={i18n.emailPlaceholder} className='max-w-sm border-none bg-white p-6 text-black placeholder:text-black placeholder:opacity-50' disabled={subscribing} onChange={validateEmail} onKeyDown={(e) => e.key === 'Enter' && subscribe()} />
            <div className='absolute bottom-[-24px] left-2 whitespace-nowrap text-sm text-red-500'>{errorMsg}</div>
          </div>
          <Button className='mb-6 w-[200px] bg-white p-6 px-12 font-semibold text-black md:m-0' disabled={!emailValid || subscribing} onClick={subscribe}>{subscribing ? i18n.subscribing : i18n.subscribe}</Button>
        </Reveal>}

        {subscribed && <Reveal id='SUBSCRIBE' duration={250} delay={250} scale={0} className='relative z-10 flex w-full flex-col flex-nowrap items-center justify-center gap-4'>
          <div className='text-xl font-semibold'>{email}</div>
          <div className='text-center text-lg'>{i18n.keepInTouch}</div>
          <Button className='mb-6 bg-white p-6 px-12 font-semibold text-black md:m-0' onClick={() => { setSubscribed(false); setEmail('') }}>{i18n.subscribeNew}</Button>
        </Reveal>}

      </FlexCol>

    </Section>
  )
}
